import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomeView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
  {
    path: "/",
    component: () => import("@/views/home/index.vue"),
  },
  {
    path: "/company",
    component: () => import("@/views/company/index.vue"),
  },
  {
    path: "/news",
    component: () => import("@/views/company/news.vue"),
  },
  {
    path: "/recruit",
    component: () => import("@/views/company/recruit.vue"),
  },
  {
    path: "/help",
    component: () => import("@/views/company/help.vue"),
  },
  {
    path: "/contact",
    component: () => import("@/views/contact/index.vue"),
  },
  {
    path: "/newsDetail",
    component: () => import("@/views/company/newsDetail.vue"),
  },
  {
    path: "/helpDetail",
    component: () => import("@/views/company/helpDetail.vue"),
  },
  {
    path: "/services",
    component: () => import("@/views/services/index.vue"),
  },
  {
    path: "/downloads",
    component: () => import("@/views/downloads/index.vue"),
  },
  {
    path: "/magazine",
    component: () => import("@/views/magazine/index.vue"),
  },
  // 杂志
  {
    path: "/essential",
    component: () => import("@/views/magazine/essential.vue"),
  },
  {
    path: "/product",
    component: () => import("@/views/home/product/index.vue"),
  },
  {
    path: "/productDetail",
    component: () => import("@/views/home/product/productDetail.vue"),
  },
  {
    path: "/index/zh",
    component: () => import("@/pages/home/index.vue"),
  },
  {
    path: "/company/zh",
    component: () => import("@/pages/company/index.vue"),
  },
  {
    path: "/news/zh",
    component: () => import("@/pages/company/news.vue"),
  },
  {
    path: "/newsDetail/zh",
    component: () => import("@/pages/company/newsDetail.vue"),
  },

  {
    path: "/recruit/zh",
    component: () => import("@/pages/company/recruit.vue"),
  },
  {
    path: "/help/zh",
    component: () => import("@/pages/company/help.vue"),
  },
  {
    path: "/helpDetail/zh",
    component: () => import("@/pages/company/helpDetail.vue"),
  },
  {
    path: "/contact/zh",
    component: () => import("@/pages/contact/index.vue"),
  },
  {
    path: "/services/zh",
    component: () => import("@/pages/services/index.vue"),
  },
  {
    path: "/downloads/zh",
    component: () => import("@/pages/downloads/index.vue"),
  },
  {
    path: "/magazine/zh",
    component: () => import("@/pages/magazine/index.vue"),
  },
  {
    path: "/essential/zh",
    component: () => import("@/pages/magazine/essential.vue"),
  },
  // 杂志 - 故事
  {
    path: "/story/zh",
    component: () => import("@/pages/magazine/story.vue"),
  },
  {
    path: "/story",
    component: () => import("@/views/magazine/story.vue"),
  },
  {
    path: "/product/zh",
    component: () => import("@/pages/home/product/index.vue"),
  },
  {
    path: "/productDetail/zh",
    component: () => import("@/pages/home/product/productDetail.vue"),
  },
  // 产品列表
  {
    path: "/productList/zh",
    component: () => import("@/pages/home/productList.vue"),
  },
  {
    path: "/productList",
    component: () => import("@/views/home/productList.vue"),
  },
  // 产品详情
  {
    path: "/productInfo/zh",
    component: () => import("@/pages/home/productInfo.vue"),
  },
  {
    path: "/productInfo",
    component: () => import("@/views/home/productInfo.vue"),
  },
  // 产品详细列表
  {
    path: "/singleProduct/zh",
    component: () => import("@/pages/home/singleProduct.vue"),
  },
  {
    path: "/singleProduct",
    component: () => import("@/views/home/singleProduct.vue"),
  },
  // 条款和条件 ToConditions
  {
    path: "/Conditions/zh",
    component: () => import("@/pages/home/toConditions.vue"),
  },
  {
    path: "/Conditions",
    component: () => import("@/views/home/toConditions.vue"),
  },
  // 法律政策
  {
    path: "/Notice/zh",
    component: () => import("@/pages/home/toNotice.vue"),
  },
  {
    path: "/Notice",
    component: () => import("@/views/home/toNotice.vue"),
  },
  // 隐私声明
  {
    path: "/privacy/zh",
    component: () => import("@/pages/home/toPrivacy.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/views/home/toPrivacy.vue"),
  },

  {
    path: "/login",
    component: () => import("@/views/login/index.vue"),
  },
  {
    path: "/login/zh",
    component: () => import("@/pages/login/index.vue"),
  },
  {
    path: "/materials",
    component: () => import("@/views/home/materials/index.vue"),
  },
  {
    path: "/materials/zh",
    component: () => import("@/pages/home/materials/index.vue"),
  },
  {
    path: "/productDetails",
    component: () => import("@/views/home/product/productDetails.vue"),
  },
  {
    path: "/productDetails/zh",
    component: () => import("@/pages/home/product/productDetails.vue"),
  },
  {
    path: "/conditions",
    component: () => import("@/views/term/conditions.vue"),
  },
  {
    path: "/conditions/zh",
    component: () => import("@/pages/term/conditions.vue"),
  },
  {
    path: "/notice",
    component: () => import("@/views/term/notice.vue"),
  },
  {
    path: "/notice/zh",
    component: () => import("@/pages/term/notice.vue"),
  },
  {
    path: "/privacy",
    component: () => import("@/views/term/privacy.vue"),
  },
  {
    path: "/privacy/zh",
    component: () => import("@/pages/term/privacy.vue"),
  },
  {
    path: "/advantageDetail",
    component: () => import("@/views/home/advantageDetail.vue"),
  },
  {
    path: "/advantageDetail/zh",
    component: () => import("@/pages/home/advantageDetail.vue"),
  },
  {
    path: "/materialsDetail",
    component: () => import("@/views/home/materials/materialsDetail.vue"),
  },
  {
    path: "/materialsDetail/zh",
    component: () => import("@/pages/home/materials/materialsDetail.vue"),
  },
  {
    path: "/servicesDetail",
    component: () => import("@/views/services/servicesDetail.vue"),
  },
  {
    path: "/servicesDetail/zh",
    component: () => import("@/pages/services/servicesDetail.vue"),
  },
  {
    path: "/applications",
    component: () => import("@/views/company/applications.vue"),
  },
  {
    path: "/applications/zh",
    component: () => import("@/pages/company/applications.vue"),
  },
  {
    path: "/search",
    component: () => import("@/views/component/searchInfo.vue"),
  },
  {
    path: "/search/zh",
    component: () => import("@/pages/component/searchInfo.vue"),
  },
  {
    path: "/infoUser/zh",
    component: () => import("@/pages/home/useInfo.vue")
  },
  {
    path: "/infoUser",
    component: () => import("@/views/home/useInfo.vue")
  }

];

const router = new VueRouter({
  routes,
});

export default router;
