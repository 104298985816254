import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    from: [],
  },
  getters: {},
  mutations: {
    addshop(state, payload) {
      console.log(payload);
      state.from.push(payload);
    },
    del(state, payload) {
      console.log(payload);
      state.from.forEach((item, index) => {
        if (item.id == payload.id) {
          state.from.splice(index, 1);
        }
      });
    },
  },
  actions: {},
  modules: {},
});
